<template>
    <div class="posive hei100">
      <NavComponent />
        <div class="container-fluid page-wrapper px-4 h-100" v-if="isProVersion">
            <div class="row align-items-stretch">

                <div class="col-6 pt-4">
                  <div class="box d-flex flex-column px-2 py-1">
                    <div class="d-flex hbox">
                      <p class="mb-1">Атакующие</p>
                      <div class="ml-auto">
                        <img src="@/assets/img/home1.png" v-if="!attackerHome" @click="attackerHome = true" class="mr-2 link" alt="">
                        <img src="@/assets/img/home2.png" v-if="attackerHome" class="mr-2 link" alt="">
                        <img src="@/assets/img/earth1.png" v-if="attackerHome" @click="attackerHome = false" class="mr-2 link" alt="">
                        <img src="@/assets/img/earth2.png" v-if="!attackerHome"  class="mr-2 link" alt="">
                      </div>
                    </div>
                    <div class="subs mt-1" v-for="(attacker, index) in attackers" :key="index">
                      <div class="subs_head d-flex">
                        <span class="subs_name">САБ {{index+1}}</span>

                        <select @change="getTechnolog(param.atack[index], index, 'attackers')" v-model="param.atack[index]" v-if="attackerHome" class="ml-3 subs_select link" name="">
                          <option :value="undefined">Импорт с планеты</option>
                          <option v-for="planet in planets" :key="planet.coordinates" :value="planet">{{planet.name}}</option>
                        </select>

                        <span class="ml-3 " v-if="!attackerHome">
                          <input title="Координаты планеты" type="text" class="subs_select subs_input text-center" style="width:40px" placeholder="0" v-model.number="attacker.path1" name="" value=""> :
                          <input title="Координаты планеты" type="text" class="subs_select subs_input text-center" style="width:40px" placeholder="0" v-model.number="attacker.path2" name="" value=""> :
                          <input title="Координаты планеты" type="text" class="subs_select subs_input text-center" style="width:40px" placeholder="0" v-model.number="attacker.path3" name="" value="">
                          <span class="link hov ml-2" @click="importPlanet(attacker, 'atack', index)">импортировать</span>
                        </span>

                        <span class="ml-2 " @click="deleteSab(index)" v-if="index != 0"><img src="@/assets/img/delete.png" height="20px" class="mb-1 link" alt=""> </span>
                        <span :class="{'ml-auto':1, 'mr-2':1, link:1, select:attackerSelect == 'technology_levels' && attackerInd==index}" @click="attackerSelect = 'technology_levels'; attackerInd=index">Технологии</span>
                        <span :class="{'mx-2':1, link:1, select:attackerSelect == 'spaceships' && attackerInd==index}" @click="attackerSelect = 'spaceships'; attackerInd=index">Флот</span>
                      </div>
                      <div class="subs_body p-2 row w-100 mx-auto" v-if="attackerInd==index">
                        <div v-for="(index, item) in attacker[attackerSelect]" :key="item" class="col-6 mb-2 px-2 d-flex align-items-center justify-content-between subs_item">
                          <p class="m-0">{{getName(item)}}</p>
                          <input type="number"  class="subs_input text-center subs_select" @focus="attacker[attackerSelect][item] = attacker[attackerSelect][item]==0?'':attacker[attackerSelect][item]" @blur="attacker[attackerSelect][item] = attacker[attackerSelect][item]==''?0:attacker[attackerSelect][item]" v-model.number="attacker[attackerSelect][item]" name="" value="">
                        </div>
                      </div>
                    </div>
                      <div class="mt-2"></div>
                    <div class="subs_add link my-2 mt-auto w-100 text-center" @click="addSab(false)">
                      + добавить участника
                    </div>
                  </div>
                </div>

                <div class="col-6 pt-4">
                  <div class="box px-2 py-1 d-flex flex-column">
                    <div class="d-flex hbox">
                      <p class="mb-1">Обороняющийся</p>
                      <div class="ml-auto">
                        <img src="@/assets/img/home1.png" v-if="!defenderHome" @click="defenderHome = true" class="mr-2 link" alt="">
                        <img src="@/assets/img/home2.png" v-if="defenderHome" class="mr-2 link" alt="">
                        <img src="@/assets/img/earth1.png" v-if="defenderHome" @click="defenderHome = false" class="mr-2 link" alt="">
                        <img src="@/assets/img/earth2.png" v-if="!defenderHome"  class="mr-2 link" alt="">
                      </div>
                    </div>
                    <div class="subs mt-1" v-for="(defender, index) in defenders" :key="index">
                      <div class="subs_head d-flex">
                        <span class="subs_name">САБ {{index+1}}</span>

                        <select @change="getTechnolog(param.def[index], index, 'defenders')" v-model="param.def[index]" v-if="defenderHome" class="ml-3 subs_select link" name="">
                          <option :value="undefined">Импорт с планеты</option>
                          <option v-for="planet in planets" :key="planet.coordinates" :value="planet">{{planet.name}}</option>
                        </select>

                        <span class="ml-3 " v-if="!defenderHome">
                          <input title="Координаты планеты" type="text" class="subs_select subs_input text-center" style="width:40px" placeholder="0" v-model.number="defender.path1" name="" value=""> :
                          <input title="Координаты планеты" type="text" class="subs_select subs_input text-center" style="width:40px" placeholder="0" v-model.number="defender.path2" name="" value=""> :
                          <input title="Координаты планеты" type="text" class="subs_select subs_input text-center" style="width:40px" placeholder="0" v-model.number="defender.path3" name="" value="">
                          <span class="link hov ml-2" @click="importPlanet(defender, 'def', index)">импортировать</span>
                        </span>

                        <span class="ml-2 " @click="deleteSab(index, true)" v-if="index != 0"><img src="@/assets/img/delete.png" height="20px" class="mb-1 link" alt=""> </span>
                        <span :class="{'ml-auto':1, 'mr-2':1, link:1, select:defenderSelect == 'technology_levels' && defenderInd==index}" @click="defenderSelect = 'technology_levels'; defenderInd=index">Технологии</span>
                        <span :class="{'mx-2':1, link:1, select:defenderSelect == 'spaceships' && defenderInd==index}" @click="defenderSelect = 'spaceships'; defenderInd=index">Флот</span>
                        <span  v-if="index == 0" :class="{'mx-2':1, link:1, select:defenderSelect == 'buildings' && defenderInd==index}" @click="defenderSelect = 'buildings'; defenderInd=index">Оборона</span>
                      </div>
                      <div class="subs_body p-2 row w-100 mx-auto" v-if="defenderInd==index">
                        <div v-for="(index, item) in defender[defenderSelect]" :key="defender.path1+item" class="col-6 mb-2 px-2 d-flex align-items-center justify-content-between subs_item">
                          <p class="m-0">{{getName(item)}}</p>
                          <input type="number"  class="subs_input text-center subs_select" @focus="defender[defenderSelect][item] = defender[defenderSelect][item]==0?'':defender[defenderSelect][item]" @blur="defender[defenderSelect][item] = defender[defenderSelect][item]==''?0:defender[defenderSelect][item]" v-model.number="defender[defenderSelect][item]" name="" value="">
                        </div>
                      </div>
                    </div>
                    <div class="mt-2"></div>
                    <div class="subs_add link my-2 mt-auto w-100 text-center" @click="addSab(true)">
                      + добавить участника
                    </div>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex mt-2">
                <span v-if="load" class="loading">Идет бой</span>
                <span v-if="!load && wins=='defender'">Бой выиграл: <b>Обороняющийся</b> </span>
                <span v-if="!load && wins=='attacker'">Бой выиграл: <b>Атакующий</b> </span>
                <span v-if="!load && wins=='draw'">Результат: <b>Ничья</b> </span>
                <span class="ml-auto btnfigth link" @click="fight">Бой</span>
              </div>
              <div class="col-12" v-if="debris">
                Обломков выпало: {{ debris.titanium }} титана и {{ debris.silicon }} кремния
              </div>
            </div>
            <div class="row mt-2 justify-content-around" >
                <Card ref="attack" :step="selectRound" :rounds="rounds" сname="attacker"></Card>
                <Card ref="attack" :step="selectRound" :rounds="rounds" сname="defender"></Card>
              <div class="col-12 d-flex mt-2  px-0">
                <div class="w-100 mr-auto text-center" v-if="rounds && rounds.length>0" style="color: rgba(255, 255, 255, 0.44); line-height: 1.4; max-width:100px;">Раунды:</div>
                <div @click="selectRound = step.round" :class="'main_btn_1 mx-1 '+ (step.round==selectRound?'select':'') " v-for="step in rounds"  :key="step.round">{{step.round+1}}</div>
              </div>
            </div>
        </div>
        <div class="container-fluid page-wrapper px-4 h-100 text-center" else>
          Симулятор доступен лишь в PRO-версии
        </div>
        <LogComponent/>
    </div>
</template>

<script>
import NavComponent from '@/components/basic_component/NavComponent.vue'
import GlxProps from '@/assets/js/glxprops'
import LogComponent from '@/components/basic_component/LogComponent.vue'
import Card from '@/components/simulator/CardComponent.vue'
import config from '@/config'
//mport store from '@/store'

//const WS_SERVER = 'wss://srv2.warforgalaxy.com:9980'
// const WS_SERVER_DEV = 'wss://srv2.warforgalaxy.com:9980'

/**
 * TODO
 * 1. Добавление САБов для каждой стороны
 * 2. Доверстать красиво
 * 3. Вывод результата боя
 */

export default {
    name: 'Simulator',
    components: {
      Card,
      LogComponent,
      NavComponent
    },
    data() {
        return {
            defenders: [],
            attackers: [],
            debris: null,

            defenderHome: false,
            attackerHome: true,

            attackerInd:0,
            defenderInd:0,
            attackerSelect: "technology_levels",
            defenderSelect: "technology_levels",

            wins: '',
            rounds:[],
            selectRound: 0,

            planets:[],
            load: false,
            technologies: {},

            socketMsgId: 0,
            rndId: 0,
            param: {
              atack: [],
              def: [],
            }
        }
    },
    created() {
        this.defenders.push(this.getEmptyMember(true))
        this.attackers.push(this.getEmptyMember())

        this.rndId = this._getRandomInt()
        this.loadTech()
        this.getMyPlanets()

        if (localStorage.getItem("pathSimulator")) {
          let path = localStorage.getItem("pathSimulator").split(':')
          this.defenders[0].path1 = path[0]
          this.defenders[0].path2 = path[1]
          this.defenders[0].path3 = path[2]
          localStorage.removeItem("pathSimulator")
          this.importPlanet(this.defenders[0])
        }
    },
    methods: {
        importPlanet(user, sub=false, index=false){
          if (sub) {
            this.param[sub][index] = undefined
          }
          if (!user.path1 && !user.path2 && !user.path3)
            return
          this.$axios.get("/info/planet?cluster="+user.path1+"&star="+user.path2+"&planet="+user.path3 ).then(res=>{
            var variable
            if (user.buildings){
              for (variable in user.buildings) {
                user.buildings[variable] = 0
              }
              for (var defence in res.data.defences) {
                this.$set(user.buildings, defence, res.data.defences[defence])
              }
            }
            if (user.spaceships){
              for (variable in user.spaceships) {
                user.spaceships[variable] = 0
              }
              for (var ship in res.data.ships) {
                this.$set(user.spaceships, ship, res.data.ships[ship])
              }
            }
            if (user.technology_levels){
              for (variable in user.technology_levels) {
                user.technology_levels[variable] = 0
              }
              for (var technologie in res.data.technologies) {
                this.$set(user.technology_levels, technologie, res.data.technologies[technologie])
              }
            }

          })
        },
        getTechnolog(value, index, sub){
          console.log(value);
          this[sub][index] = this.getEmptyMember(true)
          if (!value) {
            return
          }
          this[sub][index].path1 = value.coordinates.split(':')[0]
          this[sub][index].path2 = value.coordinates.split(':')[1]
          this[sub][index].path3 = value.coordinates.split(':')[2]

          for (var defence in value.defences) {
              this[sub][index].buildings[defence] = value.defences[defence]
          }
          for (var ship in value.ships) {
              this[sub][index].spaceships[ship] = value.ships[ship]
          }
          for (var technologie in this.technologies) {
              this[sub][index].technology_levels[technologie] = this.technologies[technologie]
          }
        },
        getMyPlanets(){
          this.$axios.get("/user/planets/units" ).then(res=>{
            if (res.data)
              this.planets = res.data
          })
        },
        addSab(deffer = false){
          if (!deffer) {
            this.attackers.push(this.getEmptyMember())
          }else {
            this.defenders.push(this.getEmptyMember())
          }
        },
        deleteSab(ind, deffer = false){
          if (!deffer) {
            if (this.attackerInd == ind) this.attackerInd = --ind
            this.attackers.splice(ind, 1)
          }else {
            if (this.defenderInd == ind) this.defenderInd = --ind
            this.defenders.splice(ind, 1)
          }
        },
        loadTech(){
          this.$axios.get("/user/technologies" ).then(res=>{
            for (var i = 0; i < res.data.length; i++) {
              this.technologies[res.data[i].name] = res.data[i].level
            }
          })
        },
        getName(k) {
            return GlxProps['defense'][k]
                 ||GlxProps['spaceships'][k]
                 ||GlxProps['technologies'][k]
                 || k
        },
        getEmptyMember(hasDefence = false) {
            const r = {
                technology_levels: this._fillWithZero('technologies'),
                spaceships: this._fillWithZero('spaceships')
            }

            if (hasDefence) {
                r['buildings'] = this._fillWithZero('defense')
            }

            return r
        },
        _fillWithZero(k) {
            if (GlxProps[k] === undefined) throw new Error('Unknown glxprops key')

            const result = {}
            for (let prop in GlxProps[k]) {
                result[prop] = 0
            }

            return result
        },
        _wait(ms) {
          return new Promise(resolver => {
            setTimeout(resolver, ms)
          })
        },
        async fight() {
            this.rounds = []
            if (this.load) return
            this.load = true
            this.selectRound = 0
            const socket = await this._connect(config.wsSimulator)
            this._auth(socket, this.userToken)
            await this._wait(1500)
            this._battle(socket, {
                defenders: this.defenders,
                attackers: this.attackers
            })

            socket.onerror = err => {
                console.error(err)
            }

            socket.onmessage = ev => {
                try {
                    const json = JSON.parse(ev.data)
                    if (json.result && json.result.rounds === undefined) return;
                    socket.close()
                    this.wins = json.result.status
                    this.rounds = json.result.rounds
                    this.debris = json.result.debris
                    this.load = false
                    // тут формируешь результат боя
                    // this.fightingResult = this.buildReport(json.result)
                } catch (err) {
                    this.load = false
                    socket.close()
                    console.error(err)
                }
            }
        },
        _connect(wsUrl) {
            return new Promise((resolve, reject) => {
                const timerId = setTimeout(() => reject(`connection timeout`), 3000)

                const socket = new WebSocket(wsUrl)
                socket.onopen = () => {
                    clearTimeout(timerId)
                    resolve(socket)
                }
            })
        },
        _getRPCMsgId() {
            return `simulator_${this.rndId}_${++this.socketMsgId}`
        },
        _getRandomInt(min = 1, max = 1000) {
            return Math.round(Math.random() * (max - min) + min)
        },
        _auth(socket, token) {
            const message = {
                "id": this._getRPCMsgId(),
                "jsonrpc": "2.0",
                "method": "auth.token",
                "params": { "token": token }
            }
            socket.send(JSON.stringify(message))
        },
        _battle(socket, params) {

            const message = {
                "id": this._getRPCMsgId(),
                "jsonrpc": "2.0",
                "method": "api.battle.simulate",
                "params": params
            }
            socket.send(JSON.stringify(message))
        }
    },
    computed: {
        isProVersion() {
            return this.$store.getters.isProVersion
        },
        userToken() {
          return this.$store.getters.token
        }
    }
}
</script>

<style media="screen" scoped="">
.btnfigth{
  padding: 5px 80px;
  background: var(--simulator-btnfight-bg);
}
.btnfigth:hover{
  background: var(--simulator-btnfight-bg-hover);
}
.subs_add{
  background: var(--simulator-subsadd-bg);
  padding: 3px 10px;
  width: 100%;

}
.subs_head{
  background: var(--simulator-subhead-bg);
  font-size: 13px;
  padding: 3px 10px;
}
.subs_select{
  background: none;
  color: var(--simulator-sub-select-color);
  outline: none;
  border: none;
}
.subs_body{
  /* min-height: 100px; */
  background: var(--simulator-subs-body);
}
.subs_item{
  font-size: 15px;
}
.subs_input{
  width: 50px;

  font-size: 13px;
  border: 1px solid var(--simulator-subs-input-border-color);
}
.subs_name{
  font-weight: 500;
  color: var(--simulator-subsname-color);
}
.subs_select>option{
  background: var(--simulator-subsselect-option-bg);
}
.box{
  width: 100%;
  min-height: 400px;
  height: 100%;
  background: var(--simulator-box-bg);
}
.link{
  cursor: pointer;
}
.link.hov:hover{
  color: var(--simulator-link-hover-color);
}
.link.select{
  font-weight: 500;
  text-decoration: underline;
}
.hbox{
  font-weight: normal;
  font-size: 20px;
}
body {
  overflow-y: hidden!important;
}
.posive {
  height: 100%;
    /* position: relative!important; */
}
.hei100 {
    height: calc(100% - 50px);
}
.preload {
    position:absolute;
    top: 0;
    left: 0;
    z-index: 33333;
    height: 10px;
    width: 100%;
}

.podnav {
    position: absolute!important;
}

.page-wrapper {
  overflow: auto;
  height: 100%;
  padding: 0px 0 90px 0;
  min-height: 100vh;
  background: var(--simulator-page-wrapper-bg);
  color: var(--simulator-page-wrapper-color);
}
.subs_input::-webkit-outer-spin-button,
.subs_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.subs_input[type=number] {
  -moz-appearance: textfield;
}

.main_btn_1{
  padding: 0 35px;
  cursor: pointer;
  line-height: 1.4;
  font-size: 15px;
  background: var(--simulator-main-btn);
}
.main_btn_1:hover{
  background: var(--simulator-main-btn-hover);
}
.main_btn_1.select{
  background: var(--simulator-main-btn-select);
  color: var(--simulator-main-btn-select-color);
}
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;    
  }
}

[data-theme="light"] .hbox img {
  filter: invert(0.7)
}


</style>
